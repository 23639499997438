<template>
    <div id="cardapio">
        <div class="top">
            <NavBarCardapio/>
            <div class="container container-menu-cardapio">
                <div class="row">
                    <div class="menu_cardapio">
                        <v-tabs dark show-arrows v-model="tab">
                            <v-tabs-slider color="#FE8204"></v-tabs-slider>
                            <v-tab v-for="categoria in $store.getters.cardapio" :key="categoria.id">
                                {{categoria.nome}}
                            </v-tab>
                        </v-tabs>
                    </div>
                </div>
            </div>
        </div>
        <div class="container middle">
            <div class="row">
                <div class="wrapper_itens_cardapio">
                    <v-tabs-items v-model="tab">
                        <v-tab-item class="category-wrapper" v-for="categoria in $store.getters.cardapio" :key="categoria.id">
                            <TabContent class="tab_content_" :itens="categoria.cardapio" :categoria="categoria.id" @handleClick="openModalProduto"/>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </div>
        </div>
        <FooterCardapio/>
        <TabMenuMobile v-if="$store.getters.screen_size.width <= 767"/>
        <ModalAddProduto 
            v-if="idAddProduto != null && categoriaAddProduto != null && showModalAddProduto" 
            :show="showModalAddProduto" 
            :id="idAddProduto" 
            :tipo_produto="tipoAddProduto" 
            :categoria="categoriaAddProduto"
            @handleClose="()=>{
                showModalAddProduto = false
                idAddProduto = null
                categoriaAddProduto = null
            }"
        />
    </div>
</template>

<script>
	import store from '@/store'
    import NavBarCardapio from '@/components/NavBarCardapio.vue'
    import TabContent from './components/TabContent.vue'
    import FooterCardapio from './components/FooterCardapio.vue'
    import ModalAddProduto from './components/ModalAddProduto.vue'
    import TabMenuMobile from '@/components/TabMenuMobile.vue'
    import CestaCompras from '@/assets/images/CESTADECOMPRAS.png'
    import CestaComprasMobile from '@/assets/images/CESTADECOMPRAS-mobile.png'

    export default {
        name: 'Cardapio',
        components: {
            NavBarCardapio,
            TabContent,
            FooterCardapio,
            ModalAddProduto,
            TabMenuMobile
        },
        data: () => ({
            tab: null,
            showModalAddProduto: false,
            idAddProduto: null,
            categoriaAddProduto: null,
            tipoAddProduto: null,
            iconeCesta: CestaCompras,
            iconeCestaMobile: CestaComprasMobile,
        }),
        methods: {
            async init(){
                await this.getCardapio();
            },
            async getCardapio(){
                await store.dispatch('getCardapio', {
                    codigo_empresa_servico: store.getters.empresa_cardapio.codigo_empresa_servico,
                    codigo_empresa_id: store.getters.empresa_cardapio.codigo_empresa_id,
                    tipo:'produtos'
                });
            },
            async openModalProduto({categoria, id, tipo_produto}){
                this.idAddProduto = id;
                this.tipoAddProduto = tipo_produto;
                this.categoriaAddProduto = categoria;
                this.showModalAddProduto = true;
            },
        },
		mounted(){
            this.init()
		},
    }
</script>

<style lang="scss">
    #cardapio{
        .top{
            width: 100%;
            position: fixed;
            background-color: #fff;
            z-index: 2;
        }
        .container-menu-cardapio{
            padding-bottom: 0;
            padding-top: 0;
        }
        .menu_cardapio{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .v-tabs{
                display: flex;
                .v-tabs-bar{
                    background-color: transparent;
                    min-width: 100%;
                    height: auto;
                    .v-slide-group__prev,
                    .v-slide-group__next{
                        min-width: 35px;
                        background: #404040;
                        margin-bottom: 5px;
                        span{
                            svg{
                                path{
                                    fill: #fff;
                                }
                            }
                        }
                        @media (max-width: 767px){
                            display: none;
                        }
                    }
                    .v-slide-group__wrapper{
                        padding-bottom: 5px;
                        .v-tabs-bar__content{
                            background: #404040;
                        }
                        .v-tab{
                            background: #404040;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: bold;
                            font-size: 20px;
                            line-height: 35px;
                            text-transform: uppercase;
                            color: #F2F2F2;
                            padding: 11px 30px;
                            letter-spacing: inherit;
                            flex: initial;
                            min-width: 350px;
                            @media (max-width: 767px) {
                                min-width: auto;
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                        .v-tabs-slider-wrapper{
                            height: 10px !important;
                            bottom: -5px !important;
                            .v-tabs-slider{
                                box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
                                border-radius: 50px;
                            }
                        }
                    }
                }
            }
        }
        .wrapper_itens_cardapio{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding-top: 165px;
            padding-bottom: 115px;
            .v-window__container,
            .v-tabs-items{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
            }
            .category-wrapper{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                border: 2px solid #f2f2f2;
            }
            @media (max-width: 767px){
                padding-top: 100px;
            }
        }
    }
</style>
<template>
     <v-dialog 
        class="v-dialog-minha-conta" 
        v-model="show_modal" 
        max-width="1120px" 
        persistent 
        :fullscreen="$store.getters.screen_size.width <= 767 ? true : false"
        transition="dialog-bottom-transition"
    >
        <v-card id="modal-conta">
            <div class="modal-minha-conta">
                <button class="close-modal" @click="close(false)">
                    <v-icon>fas fa-times</v-icon>
                </button>
                <div class="col-12 div-minha-conta">
                    <p>
                        Minha conta
                    </p>
                </div>
                <div class="col-12 itens-pedido">
                    <div class="itens">
                        <ul>
                            <li v-for="(pedido,index) in conta" :key="index">
                                <div class="headers row" v-if="pedido.quantidade > 0">
                                    <div class="header-nome header-text col-lg-4 col-md-3 col-sm-6 col-12">
                                        <span>Item</span>
                                        <div class="nome-item item-text">
                                            {{pedido.nome}}
                                        </div>
                                    </div>
                                    <div class="header-qtde header-text col-lg-1 col-md-2 col-sm-2 col-12">
                                        <span>QTD</span>
                                        <div class="qtde-item item-text">
                                            <div class="wrapper-add-remove">
                                                <button @click="pedido.quantidade--,setQtdItemPedido(pedido)" v-if="pedido.quantidade > 0">
                                                    <span class="traco_um"></span>
                                                </button>
                                                <span>
                                                    {{pedido.quantidade}}
                                                </span>
                                                <button @click="pedido.quantidade++,setQtdItemPedido(pedido)">
                                                    <span class="traco_um"></span>
                                                    <span class="traco_dois"></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="header-valor header-text col-lg-2 col-md-2 col-sm-4 col-12">
                                        <span>Total</span>
                                        <div class="valor-item item-text">
                                            {{(pedido.preco * pedido.quantidade).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                        </div>
                                    </div>
                                    <div class="header-observacoes header-text col-lg-5 col-md-4 col-sm-12 col-12">
                                        <span>Observações:</span>
                                        <div class="observacoes-item item-text">
                                            <p v-for="(complemento,index_grupo) in getComplementosByPorTipo(pedido,false)" :key="index_grupo">
                                                <span
                                                    class="observacoes-item item-text"
                                                    v-for="ingredientes in complemento.complementos"
                                                    :key="ingredientes.id"
                                                >
                                                    {{ingredientes.nome}}
                                                </span>
                                            </p>
                                            <p v-if="pedido.observacoes">
                                                <span>
                                                    {{pedido.observacoes}}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="infos-item row" v-if="pedido.quantidade > 0">
                                    <div class="col-12" v-for="(complemento,index_grupo) in getComplementosByPorTipo(pedido,true)" :key="index_grupo">
                                        <div v-if="!complemento.por_quantidade">
                                            <div
                                                class="observacoes-item item-text"
                                                v-for="ingredientes in complemento.complementos"
                                                :key="ingredientes.id"
                                            >
                                                {{ingredientes.nome}}
                                            </div>
                                        </div>

                                        <div v-if="complemento.por_quantidade" class="extras-itens_">
                                            <div class="headers secondary-headers row" >
                                                <div class="secondary-headers-nome header-text col-12">
                                                    {{complemento.nome}}
                                                </div>
                                            </div>
                                            <div
                                                class="infos-item infos-extras row"
                                                v-for="complementos in complemento.complementos"
                                                :key="complementos.id"
                                            >
                                                <span class="qtde-item item-text">
                                                    {{complementos.quantidade}}
                                                </span>
                                                <span class="nome-item item-text">
                                                    {{complementos.nome}}
                                                </span>
                                                <span class="valor-item item-text">
                                                    + {{(complementos.valor * complementos.quantidade).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="valor-conta">
                        <div class="total">
                            <div class="row">
                            <div class="col-6">
                                Situação: {{status_pedido}}
                            </div>
                            <div class="col-6">
                                Total: {{total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <Loader v-if="$store.getters.status_carrinho == 'loading'"/> -->
        </v-card>
    </v-dialog>
</template>

<script>
    import store from '@/store'
    import CestaCompras from '@/assets/images/CESTADECOMPRAS.png'
    // import Loader from '@/components/Loader2.vue'

    export default {
		name: 'ModalMinhaConta',
        components: {
            // Loader,
        },
        props: [
            "show",
            "id",
            "categoria",
        ],
        data: () => ({
            show_modal: false,
            iconeCesta: CestaCompras,
            conta: [],
            total: 0,
            status_pedido: ''
        }),
		methods: {
            async init(){
                await this.minhaConta()
                this.show_modal = this.show

                const pedido = await store.dispatch('getStatusPedido', localStorage.getItem('pedido_mesa_id'))

                this.status_pedido = pedido.dados_pedido[0].situacao
            },
            async close(adicionado){
                this.show_modal = await false
                setTimeout(() => {
                    this.$emit('handleClose', adicionado)
                }, 300);
            },
            async minhaConta(){
                this.conta = store.getters.conta
                
                for(let i = 0; i < this.conta.length; i++){
                    this.total += this.conta[i].preco * this.conta[i].quantidade
                }
            },
            getComplementosByPorTipo(produto,por_quantidade){
                let complementos = [];
                for (let index = 0; index < produto.grupo_complementos.length; index++) {
                    const element = produto.grupo_complementos[index];
                    
                    if(por_quantidade == element.por_quantidade){
                        complementos.push(element);
                    }
                }
                return complementos
            },
		},
        mounted(){
            this.init()
		},
	}
</script>

<style lang="scss">
    .v-dialog{
        box-shadow: none
    }
    #modal-conta{
        background-color: transparent !important;
        box-shadow: none !important;
        border-radius: 40px !important;
        @media (max-width: 767px) {
            border-radius: 0px !important;
        }
    }
    .modal-minha-conta{
        list-style: none;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        padding: 15px;
        .close-modal{
            background-color: transparent !important;
            position: absolute;
            right: 40px;
            top: 24px;
            font-size: 20px;
            svg{
                font-size: 25px;
                margin-left: 10px;
                background-color: transparent;
                width: 40px;
                height: 40px;
                padding: 8px;
                border-radius: 30px;
                transition: all ease .3s;
                path{
                    fill: $primarycolor;
                }
                &:hover{
                    background-color: #f3f3f3;
                }
                &:active{
                    background-color: #dadada;
                }
            }
            @media (max-width: 767px){
                top: 8px;
            }
        }
        .divider{
            width: 100%;
        }
        .div-minha-conta{
            padding: 0 15px;
            background-color: #404040;
            border-radius: 15px 15px 0 0;
            p{
                color: #fff;
                font-family: 'Poppins';
                font-weight: 600;
                text-transform: uppercase;
                font-size: 18px;
                text-align: center;
                margin-bottom: 0;
                padding: 15px;
                width: 100%;
            }
        }
        .itens-pedido{
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            padding: 0;
            .itens{
                width: 100%;
                ul{
                    padding: 0;
                    li{
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 30px;
                        color: #242424;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        flex: 1;
                        justify-content: space-between;
                        align-items: center;
                        background-color: #ededed;
                        padding: 0 15px;
                        .v-input{
                            margin-top: 0!important;
                        }
                        .headers{
                            width: 100%;
                            .header-qtde{
                                text-align: center;
                            }
                            .header-text{
                                display: flex;
                                flex-wrap: wrap;
                                flex-direction: column;
                                p{
                                    margin-bottom: 0;
                                }
                            }
                        }
                        &:nth-child(even){
                            background-color: #dddddd;                            
                        }
                    }
                    &.nao_por_quantidade{
                        flex: 1;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        li{
                            display: flex;
                            padding-left: 30px;
                            width: calc(100% / 3);
                            flex: initial;
                            @media (max-width: 767px) {
                                padding: 0 12px;
                                width: 100%;
                            }
                        }
                    }
                    &.por_quantidade{
                        flex: 1;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        li{
                            flex: initial;
                            width: 100%;
                            padding: 15px;
                            margin-bottom: 0;
                            &:nth-of-type(odd){
                                background: rgba(237, 237, 237, 0.4);
                            }
                        }
                    }
                }
            }
            .valor-conta{
                width: 100%;
                overflow-y: auto;
                .total{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 24px;
                    color: #FFFFFF;
                    width: 100%;
                    padding: 15px;
                    background: #404040;
                    border-radius: 0 0 15px 15px;
                    box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
                    @media (max-width: 767px) {
                        padding: 10px;
                    }
                }
            }
            @media (max-width: 767px) {
                padding: 0;
            }
        }
        @media (max-width: 767px) {
            padding: 0;
        }
    } 
</style>
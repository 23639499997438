<template>
    <ul id="tab_content_">
        <li class="li-item-cardapio" v-for="item in itens" :key="item.tipo_produto == 'pizza' ? item.codigo_tamanho : item.id">
            <div class="linha-do-produto" @click="$store.getters.screen_size.width <= 767 ? returnID((item.tipo_produto == 'pizza' ? item.codigo_tamanho : item.id), item.tipo_produto) : null">
                <div class="texto-imagem">
                    <div class="imagem" v-bind:style="`background-image: url(${item.imagem})`"/>
                    <div class="texto">
                        <div class="nome">
                            {{item.nome}}
                            <span class="referencia">
                                #{{item.referencia}}
                            </span>
                        </div>
                        <div class="descricao" v-html="item.descricao">
                        </div>
                        <div class="preco" v-if="item.valor_desconto != undefined && item.valor_desconto > 0">
                            {{item.valor_desconto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                        <div class="preco" v-else-if="item.valor != undefined && item.valor > 0">
                            {{item.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                        </div>
                    </div>
                </div>
                <div class="btn-wrapper" v-if="verificaDisponibilidade((item.tipo_produto == 'pizza' ? item.codigo_tamanho : item.id), item.tipo_produto).sucesso == true">
                    <v-btn class="adiciona-produto" @click="$store.getters.screen_size.width > 767 ? returnID((item.tipo_produto == 'pizza' ? item.codigo_tamanho : item.id), item.tipo_produto) : null">
                        <img :src="iconeCesta" alt="">
                        <span class="texto">
                            Adicionar
                        </span>
                    </v-btn>
                </div>
                <div class="btn-wrapper" v-else>
                    <span class="texto-disponivel" v-if="verificaDisponibilidade((item.tipo_produto == 'pizza' ? item.codigo_tamanho : item.id), item.tipo_produto).de">
                        Disponível das <br>{{(verificaDisponibilidade((item.tipo_produto == 'pizza' ? item.codigo_tamanho : item.id), item.tipo_produto).de).substr(0, 5)}} às {{(verificaDisponibilidade((item.tipo_produto == 'pizza' ? item.codigo_tamanho : item.id), item.tipo_produto).ate).substr(0,5)}}
                    </span>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
    import CestaCompras from '@/assets/images/CESTADECOMPRAS.png'
    import moment from 'moment'
    
	export default {
		name: 'TabContent',
        props: [
            "itens",
            "categoria",
        ],
        data: () => ({
            iconeCesta: CestaCompras,
            dia_atual: null
        }),
		methods: {
            async init(){
                await this.montaCardapio()
            },
            returnID(id, tipo_produto){
                this.$emit('handleClick', {categoria: this.categoria, id, tipo_produto})
			},
            verificaDisponibilidade(id, tipo){
                let resp = {
                    sucesso: '',
                }
                let dia_atual = moment().format('ddd')
                switch (dia_atual){
                    case 'dom':
                        dia_atual = 'domingo'
                    break;
                    case 'seg':
                        dia_atual = 'segunda'
                    break;
                    case 'ter':
                        dia_atual = 'terca'
                    break;
                    case 'qua':
                        dia_atual = 'quarta'
                    break;
                    case 'qui':
                        dia_atual = 'quinta'
                    break;
                    case 'sex':
                        dia_atual = 'sexta'
                    break;
                    case 'sab':
                        dia_atual = 'sabado'
                    break;
                }
                
                for(let i = 0; i < this.itens.length; i++){
                    if((this.itens[i].id == id && tipo == 'normal') || (this.itens[i].codigo_tamanho == id && tipo == 'pizza')){
                        if(this.itens[i].horarios_disponiveis != null){
                            for(let j = 0; j < (this.itens[i].horarios_disponiveis).length; j++){
                                if(this.itens[i].horarios_disponiveis[j][dia_atual]){
                                    if(this.itens[i].horarios_disponiveis[j]['de'] <= moment().format('LTS') && this.itens[i].horarios_disponiveis[j]['ate'] >= moment().format('LTS')){
                                        resp.sucesso = true
                                        return resp
                                    }
                                    else{
                                        resp.sucesso = false
                                        resp.de = this.itens[i].horarios_disponiveis[j]['de']
                                        resp.ate = this.itens[i].horarios_disponiveis[j]['ate']
                                        return resp
                                    }
                                }
                            }
                            resp.sucesso = false
                            return resp
                        }
                        else{
                            resp.sucesso = true
                            return resp
                        }
                    }
                }
            },
            async montaCardapio(){
                if( this.itens){
                    for(let i = 0; i < this.itens.length; i++ ){
                        this.itens[i].valor = parseFloat(this.itens[i].valor)
                        this.itens[i].valor_desconto = parseFloat(this.itens[i].valor_desconto)
                    }
                }                
            }
		},
        mounted(){
            this.init()
        }
	}
</script>

<style lang="scss">
    #tab_content_{
        list-style: none;
        padding: 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        li{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            &.li-item-cardapio{
                &:nth-of-type(odd){
                    .linha-do-produto{
                        background-color: #F2F2F2;
                    }
                }
                .linha-do-produto{
                    padding: 16px 30px;
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    .texto-imagem{
                        flex: 1;
                        display: flex;
                        flex-wrap: wrap;
                        .imagem{
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            width: 140px;
                            height: 140px;
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: center;
                            border-radius: 10px;
                            @media (max-width: 767px){
                                width: 80px;
                                height: 80px;
                            }
                        }
                        .texto{
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: column;
                            justify-content: space-between;
                            flex: 1;
                            padding-left: 25px;
                            .nome{
                                font-family: 'Poppins';
                                font-weight: 500;
                                font-size: 20px;
                                line-height: 30px;
                                color: #242424;
                                display: flex;
                                @media (max-width: 767px){
                                    font-size: 12px;
                                    line-height: 18px;
                                }
                                
                            }
                            .acompanhamento,
                            .descricao{
                                font-family: 'Josefin Sans';
                                font-weight: 300;
                                font-size: 21px;
                                line-height: 24px;
                                color: #242424;
                                p{
                                    margin-bottom: 0;
                                }
                                @media (max-width: 767px){
                                    font-size: 12px;
                                    line-height: 12px;
                                }
                            }
                            .acompanhamento{
                                font-size: 18px;
                                line-height: 18px;
                                @media (max-width: 767px){
                                    font-size: 12px;
                                    line-height: 12px;
                                }
                            }
                            .preco,
                            .referencia{
                                font-family: 'Inter';
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 19px;
                                display: flex;
                                align-items: center;
                                color: #787885;
                                margin-left: 10px;
                                @media (max-width: 767px){
                                    font-size: 12px;
                                    line-height: 18px;
                                }
                            }
                            .preco{
                                font-size: 21px;
                                line-height: 26px;
                                display: flex;
                                align-items: center;
                                color: #262626;
                                @media (max-width: 767px){
                                    font-size: 12px;
                                    line-height: 18px;
                                }
                            }
                        }
                    }
                    .btn-wrapper{
                        width: 20%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        button{
                            position: relative;
                            color: #FFF;
                            background-color: #FE8204;
                            font-family: 'Poppins';
                            font-size: 15px;
                            color: #FFF;
                            font-weight: 600;
                            padding: 15px 15px 10px;
                            border-radius: 10px;
                            height: auto;
                            min-width: auto;
                            text-transform: initial;
                            span{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                @media (max-width: 767px){
                                    &.texto{
                                        display: none;
                                    }
                                }
                            }
                            img{
                                width: 30px;
                                margin-right: 10px;
                                @media (max-width: 767px){
                                    margin-right: 0;
                                    width: 15px;
                                }
                            }
                            @media (max-width: 767px){
                                padding: 10px;
                                border-radius: 100%;
                            }
                        }
                        .texto-disponivel{
                            color: #242424;
                            text-align: center;
                            font-weight: 600;
                            @media (max-width: 767px){
                                font-size: 14px;
                            }
                        }
                        @media (max-width: 920px){
                            width: auto;
                        }
                    }
                    @media (max-width: 767px){
                        padding: 12px;
                    }
                }
            }
        }
    } 
</style>
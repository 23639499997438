<template>
    <div class="rodape">
        <div class="container container-rodape">
            <div class="row">
                <div :class="'col-12 div-itens-rodape ' + (mesa.id != null ? 'tem-mesa' : '')">
                    <div class="div-mesa" v-if="mesa.nome != null">
                        <span>{{mesa.nome}}</span>
                    </div>
                    <div class="sacola">
                        <div class="itens-sacola" @click="verCarrinho()" v-if="$store.getters.qtde_produtos_no_carrinho > 0">
                            {{$store.getters.qtde_produtos_no_carrinho}}
                            <span v-if="$store.getters.qtde_produtos_no_carrinho == 1">
                                item
                            </span>
                            <span v-else>
                                itens
                            </span>
                        </div>
                        <div class="itens-sacola" v-else>
                            <span @click="verCarrinho()">Sacola vazia</span>
                        </div>
                        <div class="div-ver-sacola" v-if="$store.getters.screen_size.width <= 767">
                            <button @click="verCarrinho()">
                                <img :src="iconeCestaMobile" alt="">
                            </button>
                        </div>
                        <div class="div-ver-sacola" v-else>
                            <v-btn @click="verCarrinho()" v-tooltip.top-center="'Ver sacola'">
                                <v-badge
                                    :content="$store.getters.qtde_produtos_no_carrinho"
                                    :value="$store.getters.qtde_produtos_no_carrinho"
                                    color="#FE8204"
                                    overlap
                                    style="padding: 0px;"
                                    bottom
                                    offset-x="16"
                                    offset-y="18"
                                >
                                    <img :src="iconeCesta" alt="">
                                </v-badge>
                                <span v-if="!mesa.id">
                                    Ver sacola
                                </span>
                                <img :src="iconeArrow" alt="" v-if="!mesa.id">
                            </v-btn>
                            <v-btn @click="verConta()" v-tooltip.top-center="'Minha conta'" v-if="mesa.id">
                                <img :src="iconeConta" alt="">
                            </v-btn>
                            <v-btn @click="fecharConta()" v-tooltip.top-center="'Fechar conta'" v-if="mesa.id && $store.getters.conta != ''">
                                <img :src="iconePagamento" alt="">
                            </v-btn>
                            <v-btn @click="sairMesa()" v-if="mesa.id && $store.getters.conta == ''">
                                <span class="sair-mesa">
                                    Sair da mesa
                                </span>
                            </v-btn>
                        </div>
                        <div class="total" v-if="$store.getters.screen_size.width > 767">
                            Subtotal
                            <span v-if="mesa.id">
                                {{(total + $store.getters.total_pedido).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                            </span>
                            <span v-else>
                                {{$store.getters.total_pedido.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalMinhaConta 
            v-if="showModalMinhaConta"
            :show="showModalMinhaConta"
            @handleClose="()=>{
                showModalMinhaConta = false
            }"
        />
    </div>
</template>

<script>

    import Vue from 'vue'
    import store from '@/store'
    import CestaCompras from '@/assets/images/CESTADECOMPRAS.png'
    import Conta from '@/assets/images/conta.png'
    import Pagamento from '@/assets/images/pagamento.png'
    import RightArrow from '@/assets/images/bx_bxs-right-arrow.png'
    import CestaComprasMobile from '@/assets/images/CESTADECOMPRAS-mobile.png'
    import ModalMinhaConta from '@/components/ModalMinhaConta.vue'
    import VTooltip from 'v-tooltip'
    Vue.use(VTooltip)

	export default {
		name: 'FooterCardapio',
        components: {
            ModalMinhaConta,
        },
        props: [
            "categoria",
        ],
        data: () => ({
            iconeCesta: CestaCompras,
            iconeConta: Conta,
            iconePagamento: Pagamento,
            iconeCestaMobile: CestaComprasMobile,
            iconeArrow: RightArrow,
            mesa: {
                id: null,
                nome: null,
            },
            total: 0,
            showModalMinhaConta: false,
            pedido_mesa_id: null,
        }),
		methods: {
            async init(){
                let urlParams = new URLSearchParams(window.location.search);
                
                let _mesa = urlParams.get('mesa') == undefined ? JSON.parse(localStorage.getItem('mesa_id')) : urlParams.get('mesa');
                
                if(_mesa != undefined && _mesa  != ''&& _mesa  != null){
                    let resp = await store.dispatch('verificaMesa', {
                        codigo_empresa_id: store.getters.empresa_cardapio.codigo_empresa_id,
                        mesa_id: urlParams.get('mesa') == undefined ? JSON.parse(localStorage.getItem('mesa_id')) : urlParams.get('mesa')
                    });

                    if(resp.data.sucesso){
                        this.mesa.id = urlParams.get('mesa') == undefined ? JSON.parse(localStorage.getItem('mesa_id')) : urlParams.get('mesa')
                        this.mesa.nome = resp.data.dados.nome_mesa
                    }
                }
                await this.minhaConta()
            },
            returnID(id){
				this.$emit('handleClick', id)
			},
            async verCarrinho(){
                if(store.getters.qtde_produtos_no_carrinho == 0){
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
					    text: 'Sua sacola está vazia!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                }else{
                    if(this.mesa.id){
                        this.$router.push('/' + this.$route.params.url_amigavel + '/finalizar?mesa=' + this.mesa.id)
                    }else{
                        this.$router.push('/' + this.$route.params.url_amigavel + '/finalizar')
                    }
                }
            },
            async verConta(){
                if(store.getters.conta == ''){
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
					    text: 'Sua conta está vazia!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                }else{
                    this.showModalMinhaConta = !this.showModalMinhaConta
                }
            },
            async minhaConta(){
                this.conta = store.getters.conta
                
                for(let i = 0; i < this.conta.length; i++){
                    const element = JSON.parse(JSON.stringify(this.conta[i]));
                    this.total += this.conta[i].preco * this.conta[i].quantidade
                    element.grupo_complementos.forEach(grupo_complemento => {
                        grupo_complemento.complementos.forEach(complemento => {
                            if(complemento.valor != null ){
                                this.total += complemento.valor * complemento.quantidade
                            }
                        });
                    });
                }
            },
            async fecharConta(){
                let urlParams = new URLSearchParams(window.location.search);
                this.loading = await true
                var fechar_conta = true
                var resp_conta = false
                // Verifica se a conta já foi fechada pelo estabelecimento
                var verifica_conta = await store.dispatch('verificaConta', store.getters.pedido_mesa_id || localStorage.getItem('pedido_mesa_id'))

                // Conta já fechada pelo estabelecimento
                if(verifica_conta.sucesso){
                    fechar_conta = await this.$swal({
                        title: 'Sua conta já foi fechada pelo estabelecimento!',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        }).then((result) => {
                        if (result.isConfirmed) {                        
                            return false
                        } 
                    })
                    await store.dispatch('clearConta')
                    if(urlParams.has('mesa')){
                        this.$router.push('/' + this.$route.params.url_amigavel)
                        document.location.reload(true)
                    }else{
                        document.location.reload(true)
                    }        
                }
                // Conta ainda não fechada
                if(fechar_conta){
                    resp_conta = await this.$swal({
                        title: 'Tem certeza que deseja fechar sua conta?',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText:'Não'
                        }).then((result) => {
                        if (result.isConfirmed) {                        
                            return true
                        }
                    })
                    // Solicita o fechamento caso cliente confirme selecionando "Sim" na modal
                    if(resp_conta){
                        var resp = await store.dispatch('solicitaConta', store.getters.pedido_mesa_id || localStorage.getItem('pedido_mesa_id'))
                        if (resp.sucesso) {
                            if(resp.pedido_id != undefined){
                                this.loading = await false
                                this.$swal({
                                    icon:'success',
                                    text: 'Sua solicitação foi enviada com sucesso!',
                                    showCancelButton: false,
                                    showCloseButton: false,
                                    showConfirmButton: false
                                })
                            }                            
                            await store.dispatch('clearConta')
                            if(urlParams.has('mesa')){
                                this.$router.push('/' + this.$route.params.url_amigavel)
                                document.location.reload(true)
                            }else{
                                document.location.reload(true)
                            }
                        }else{
                            this.$swal({
                                title: 'Atenção',
                                icon:'warning',
                                text: 'Não foi possível fechar sua conta!',
                                showCancelButton: false,
                                showCloseButton: false,
                                showConfirmButton: true,
                                confirmButtonText:'Ok'
                            })
                            this.loading = await false
                        }
                    }                    
                }
    
                this.loading = await false
                this.$emit('retornoTransaction',resp)
            },
            async sairMesa(){
                let urlParams = new URLSearchParams(window.location.search);
                var sair = false
                this.loading = await true
                sair = await this.$swal({
                    title: 'Tem certeza que deseja sair da mesa?',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText:'Não'
                    }).then((result) => {
                    if (result.isConfirmed) {                        
                        return true
                    } 
                })

                if(sair){
                    var resp = await store.dispatch('sairMesa')
                    if (resp) {                        
                        if(urlParams.has('mesa')){
                            this.$router.push('/' + this.$route.params.url_amigavel)
                            document.location.reload(true)
                        }else{
                            document.location.reload(true)
                        }
                    }else{
                        this.$swal({
                            title: 'Atenção',
                            icon:'warning',
                            text: 'Não foi possível sair da mesa!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true,
                            confirmButtonText:'Ok'
                        })
                        this.loading = await false
                    }                    
                }
    
                this.loading = await false
                this.$emit('retornoTransaction',resp)
            },
		},
		mounted(){
            this.init()
		},
	}
</script>

<style lang="scss">
    .rodape{
        background-color: #242424;
        position: fixed;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        @media (max-width: 767px) {
            bottom: 50px;
        }
        .container-rodape{
            padding: 0px 10px;
        }
        .div-itens-rodape{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            color: #FFFFFF;
            padding-top: 0;
            padding-bottom: 0;
            .div-mesa{
                font-size: 28px;
                text-transform: uppercase;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                line-height: 42px;
                color: #FFFFFF;
                letter-spacing: 0;
                @media (max-width: 767px){
                    font-size: 22px;
                }
            }
            .sacola{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                font-family: 'Poppins';
                .itens-sacola{
                    padding-right: 20px;
                    @media (max-width: 767px){
                        font-family: Poppins;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 30px;
                        text-align: center;
                    }
                }
                .div-ver-sacola{
                    display: flex;
                    flex-wrap: wrap;
                    padding: 0 12px;
                    button{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        height: auto;
                        max-height: initial;
                        background: #4a4a4a;
                        color: #fff;
                        border-radius: 0px;
                        border-right: 3px solid #242424;
                        span{
                            padding: 8px 12px;
                            transition: all ease .3s;
                            span{
                                padding: 4px;
                                .v-badge__badge{
                                    inset: 25px 28px;
                                }
                            }
                        }
                        img{
                            max-width: 43px;
                            margin-left: 28px;
                            transition: all ease .3s;
                            @media (max-width: 767px){
                                max-width: 17px;
                            }
                            &:nth-child(1){
                                margin-left: 0;
                            }
                        }
                        @media (max-width: 767px){
                            background: transparent;
                            font-size: 16px;
                        }
                        &:nth-last-child(1){
                            border-right: 0;
                        }
                        &:hover{
                            img{
                                transform: scale(1.1)
                            }
                            span{
                                &.sair-mesa{
                                    transform: scale(1.1)
                                }
                            }
                            .v-badge__badge{
                                transform: scale(1.1)
                            }
                        }
                    }
                }
                .total{
                    padding-left: 20px;
                    font-size: 28px;
                    text-transform: uppercase;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    line-height: 42px;
                    color: #FFFFFF;
                    letter-spacing: 0;
                }
                @media (max-width: 767px){
                    flex-direction: row-reverse;
                    justify-content: space-evenly;
                }
            }
            .ver-mais{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                font-family: 'Poppins';
                svg{
                    margin-right: 10px;
                    font-size: 18px;
                    path{
                        fill: #fff;
                    }
                }
                @media (max-width: 767px){
                    font-size: 12px;
                    button{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        font-weight: 500;
                    }
                }
            }
            .total{
                padding-left: 20px;
                font-size: 28px;
                text-transform: uppercase;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 28px;
                line-height: 42px;
                color: #FFFFFF;
                letter-spacing: 0;
                span{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 36px;
                    letter-spacing: 0;
                    color: #FFFFFF;
                    margin-left: 10px;
                }
            }
            @media (max-width: 767px){
                justify-content: space-around;
                padding: 10px 0px;
            }
            @media (max-width: 300px){
                justify-content: center;
            }
            &.tem-mesa{
                justify-content: space-between;
                @media (max-width: 767px){
                    justify-content: space-evenly;
                }
            }
        }
        @media (max-width: 767px){
            border-radius: 20px 20px 0 0;
        }
    }
    .tooltip {
        display: block !important;
        z-index: 10000;
        .tooltip-inner {
            font-family: 'Poppins';
            background: #242424;
            color: white;
            font-weight: 500;
            border-radius: 16px;
            padding: 5px 10px 4px;
        }
        .tooltip-arrow {
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
            margin: 5px;
            border-color: #242424;
            z-index: 1;
        }
        &[x-placement^="top"] {
            margin-bottom: 5px;
            .tooltip-arrow {
                border-width: 5px 5px 0 5px;
                border-left-color: transparent !important;
                border-right-color: transparent !important;
                border-bottom-color: transparent !important;
                bottom: -5px;
                left: calc(50% - 5px);
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        &[x-placement^="bottom"] {
            margin-top: 5px;
            .tooltip-arrow {
                border-width: 0 5px 5px 5px;
                border-left-color: transparent !important;
                border-right-color: transparent !important;
                border-top-color: transparent !important;
                top: -5px;
                left: calc(50% - 5px);
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        &[x-placement^="right"] {
            margin-left: 5px;
            .tooltip-arrow {
                border-width: 5px 5px 5px 0;
                border-left-color: transparent !important;
                border-top-color: transparent !important;
                border-bottom-color: transparent !important;
                left: -5px;
                top: calc(50% - 5px);
                margin-left: 0;
                margin-right: 0;
            }
        }
        &[x-placement^="left"] {
            margin-right: 5px;
            .tooltip-arrow {
                border-width: 5px 0 5px 5px;
                border-top-color: transparent !important;
                border-right-color: transparent !important;
                border-bottom-color: transparent !important;
                right: -5px;
                top: calc(50% - 5px);
                margin-left: 0;
                margin-right: 0;
            }
        }
        &[aria-hidden='true'] {
            visibility: hidden;
            opacity: 0;
            transition: opacity .15s, visibility .15s;
        }
        &[aria-hidden='false'] {
            visibility: visible;
            opacity: 1;
            transition: opacity .15s;
        }
    }
    .swal2-modal{
        .swal2-title{
            font-size: 22px;
            @media (max-width: 767px){
                font-size: 16px;
            }
        }
        .swal2-cancel{
            background-color: #404040 !important;
        }
    }
</style>
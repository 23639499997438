<template>
	<div id="TabMenuMobile">
		<div class="tabs">
			<div class="tab-link">
				<button @click="openModal">
					<v-icon color="#fff">mdi-magnify</v-icon>
					<span class="legenda">Busca</span>
				</button>
			</div>
			<div class="tab-link" v-if="!mesa.id">
				<router-link :to="$store.getters.isLoggedIn ? '/' + this.$route.params.url_amigavel + '/historico-de-pedidos' : '/' + this.$route.params.url_amigavel + '/login'">
					<figure id="logo-meus-pedidos">
						<img class="img-responsive" alt="Meus pedidos" src="@/assets/images/meus-pedidos.png">
					</figure>
					<span class="legenda">Pedidos</span>
				</router-link>
			</div>
			<div class="tab-link" v-if="mesa.id">
				<v-btn class="btn-mesa" @click="verConta()" >
					<img :src="iconeConta" alt="">
				</v-btn>
					<span class="legenda">Conta</span>
			</div>
			<div class="tab-link" v-if="mesa.id && $store.getters.conta != ''">
				<v-btn class="btn-mesa" @click="fecharConta()" >
					<img :src="iconePagamento" alt="">
				</v-btn>
				<span class="legenda">Fechar conta</span>
			</div>
			<div class="tab-link" v-if="mesa.id && $store.getters.conta == ''">
				<v-btn class="btn-mesa" @click="sairMesa()">
					<span class="sair-mesa">
						Sair da mesa
					</span>
				</v-btn>
			</div>
			<div class="tab-link" v-else>
				<router-link :to="$store.getters.isLoggedIn ? '/' + this.$route.params.url_amigavel + '/perfil' : '/' + this.$route.params.url_amigavel + '/login'">
					<v-icon color="#fff">mdi-account-circle</v-icon>
					<span class="legenda">Perfil</span>
				</router-link>
			</div>
		</div>
		<ModalBusca 
			v-if="showModalBusca"
			:show="showModalBusca"
            @handleClose="closeModal"
		/>
        <ModalMinhaConta 
            v-if="showModalMinhaConta"
            :show="showModalMinhaConta"
            @handleClose="()=>{
                showModalMinhaConta = false
            }"
        />
	</div>
</template>

<script>
    import store from '@/store'
    import ModalBusca from '@/components/ModalBusca.vue'
    import Conta from '@/assets/images/conta.png'
    import Pagamento from '@/assets/images/pagamento.png'
    import ModalMinhaConta from '@/components/ModalMinhaConta.vue'

	export default {
		components:{
            ModalBusca,
            ModalMinhaConta,
        },
		name: 'TabMenuMobile',
		data() {
			return {
				showModalBusca: false,
				showModalMinhaConta: false,
				iconeConta: Conta,
				iconePagamento: Pagamento,
				mesa: {
					id: null,
					nome: null,
				},
			}
		},
		methods: {
            async init(){
                let urlParams = new URLSearchParams(window.location.search);
                
                let _mesa = urlParams.get('mesa') == undefined ? JSON.parse(localStorage.getItem('mesa_id')) : urlParams.get('mesa');
                
                if(_mesa != undefined && _mesa  != ''&& _mesa  != null){
                    let resp = await store.dispatch('verificaMesa', {
                        codigo_empresa_id: store.getters.empresa_cardapio.codigo_empresa_id,
                        mesa_id: urlParams.get('mesa') == undefined ? JSON.parse(localStorage.getItem('mesa_id')) : urlParams.get('mesa')
                    });

                    if(resp.data.sucesso){
                        this.mesa.id = urlParams.get('mesa') == undefined ? JSON.parse(localStorage.getItem('mesa_id')) : urlParams.get('mesa')
                        this.mesa.nome = resp.data.dados.nome_mesa
                    }
                }
                await this.minhaConta()
            },
			openModal(){
				this.showModalBusca = true
			},
			closeModal(){
				this.showModalBusca = false
			},
            async verConta(){
                if(store.getters.conta == ''){
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
					    text: 'Sua conta está vazia!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                }else{
                    this.showModalMinhaConta = !this.showModalMinhaConta
                }
            },
            async minhaConta(){
                this.conta = store.getters.conta
                
                for(let i = 0; i < this.conta.length; i++){
                    this.total += this.conta[i].preco * this.conta[i].quantidade
                }
            },
            async fecharConta(){
                let urlParams = new URLSearchParams(window.location.search);
                this.loading = await true
                var fechar_conta = true
                var resp_conta = false
                // Verifica se a conta já foi fechada pelo estabelecimento
                var verifica_conta = await store.dispatch('verificaConta', store.getters.pedido_mesa_id || localStorage.getItem('pedido_mesa_id'))

                // Conta já fechada pelo estabelecimento
                if(verifica_conta.sucesso){
                    fechar_conta = await this.$swal({
                        title: 'Sua conta já foi fechada pelo estabelecimento!',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        }).then((result) => {
                        if (result.isConfirmed) {                        
                            return false
                        } 
                    })
                    await store.dispatch('clearConta')
                    if(urlParams.has('mesa')){
                        this.$router.push('/' + this.$route.params.url_amigavel)
                        document.location.reload(true)
                    }else{
                        document.location.reload(true)
                    }        
                }
                // Conta ainda não fechada
                if(fechar_conta){
                    resp_conta = await this.$swal({
                        title: 'Tem certeza que deseja fechar sua conta?',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText:'Não'
                        }).then((result) => {
                        if (result.isConfirmed) {                        
                            return true
                        }
                    })
                    // Solicita o fechamento caso cliente confirme selecionando "Sim" na modal
                    if(resp_conta){
                        var resp = await store.dispatch('solicitaConta', store.getters.pedido_mesa_id || localStorage.getItem('pedido_mesa_id'))
                        if (resp.sucesso) {
                            if(resp.pedido_id != undefined){
                                this.loading = await false
                                this.$swal({
                                    icon:'success',
                                    text: 'Sua solicitação foi enviada com sucesso!',
                                    showCancelButton: false,
                                    showCloseButton: false,
                                    showConfirmButton: false
                                })
                            }                            
                            await store.dispatch('clearConta')
                            if(urlParams.has('mesa')){
                                this.$router.push('/' + this.$route.params.url_amigavel)
                                document.location.reload(true)
                            }else{
                                document.location.reload(true)
                            }
                        }else{
                            this.$swal({
                                title: 'Atenção',
                                icon:'warning',
                                text: 'Não foi possível fechar sua conta!',
                                showCancelButton: false,
                                showCloseButton: false,
                                showConfirmButton: true,
                                confirmButtonText:'Ok'
                            })
                            this.loading = await false
                        }
                    }                    
                }
    
                this.loading = await false
                this.$emit('retornoTransaction',resp)
            },
            async sairMesa(){
                let urlParams = new URLSearchParams(window.location.search);
                var sair = false
                this.loading = await true
                sair = await this.$swal({
                    title: 'Tem certeza que deseja sair da mesa?',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText:'Não'
                    }).then((result) => {
                    if (result.isConfirmed) {                        
                        return true
                    } 
                })

                if(sair){
                    var resp = await store.dispatch('sairMesa')
                    if (resp) {                        
                        if(urlParams.has('mesa')){
                            this.$router.push('/' + this.$route.params.url_amigavel)
                            document.location.reload(true)
                        }else{
                            document.location.reload(true)
                        }
                    }else{
                        this.$swal({
                            title: 'Atenção',
                            icon:'warning',
                            text: 'Não foi possível sair da mesa!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true,
                            confirmButtonText:'Ok'
                        })
                        this.loading = await false
                    }                    
                }
    
                this.loading = await false
                this.$emit('retornoTransaction',resp)
            },
		},
		mounted(){
            this.init()
		},
	}
</script>

<style lang="scss">
	#TabMenuMobile{
		background: #242424;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
		.tabs{
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			padding: 3px 0;
			flex: 1;
			.tab-link{
				display: flex;
				flex-direction: column;
				align-items: center;
				font-family: 'Poppins';
				font-size: 12px;
				color: #FE8204;
				font-weight: 500;
				button,
				a{
					display: flex;
					flex-direction: column;
					align-items: center;
					text-transform: uppercase;
				}
				.btn-mesa{
					background-color: transparent;
					color: #FFF;
					box-shadow: none;
					height: max-content;
					padding: 0 !important;
					min-width: max-content !important;
				}
				.legenda{
					font-size: 12px;
					color: #FE8204;
					font-weight: 500;
					text-transform: uppercase;
					@media (max-width: 350px){
						display: none;
					}
				}
				i{
					font-size: 20px;
				}
				figure,
				img{
					max-width: 20px;
					max-height: 20px;
				}	
			}
		}
	}
</style>
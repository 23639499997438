<template>
     <v-dialog 
        class="v-dialog-add-produto" 
        v-model="show_modal" 
        max-width="1120px" 
        persistent 
        :fullscreen="$store.getters.screen_size.width <= 767 ? true : false"
        transition="dialog-bottom-transition"
    >
        <v-card class="modal-mensagem" v-if="item_ != null">
            <div class="modal-add-produto">
                <button class="close-modal" @click="close(false)">
                    <v-icon>fas fa-times</v-icon>
                </button>
                <div class="col-md-4 col-12 imagem">
                    <div class="img" v-bind:style="`background-image: url(${item_.imagem})`"/>
                </div>
                <div class="col-md-8 col-12">
                    <div class="texto">
                        <div class="nome">
                            {{item_.nome}}
                            <span class="referencia">
                                #{{item_.referencia}}
                            </span>
                        </div>
                        <div class="descricao" v-html="item_.descricao"/>
                        <div class="total" v-if="item_.valor">
                            <div class="preco" v-if="item_.valor_desconto">
                                {{item_.valor_desconto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                            </div>
                            <div class="preco" v-else-if="item_.valor">
                                {{item_.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                            </div>
                            <div class="preco" v-else>
                                {{(0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                            </div>
                        </div>
                    </div>
                    <div class="opcoes_extra">
                        <div class="div-wrapper-opcoes-extras wrapper-retirada" v-if="item_.ingredientes">
                            <div class="titulo">
                                Retiradas
                            </div>
                            <ul>
                                <li v-for="ingredientes in item_.ingredientes" :key="ingredientes.id">
                                    <div class="texto_extra">
                                        <v-switch
                                            hide-details
                                            :label="ingredientes.nome"
                                            v-model="ingredientes.retirar"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="div-wrapper-opcoes-extras" v-for="grupo_complementos in item_.grupo_complementos" :key="grupo_complementos.id">
                            <div class="titulo">
                                <div class="nome">
                                    {{grupo_complementos.nome}}
                                </div>
                                <div class="minmax" v-if="grupo_complementos.minimo || grupo_complementos.maximo">
                                    {{grupo_complementos.minimo ? 'Mínimo: ' + grupo_complementos.minimo + ' / ' : '' }}  {{grupo_complementos.maximo ? 'Máximo: ' + grupo_complementos.maximo : '' }}
                                    <div class="obrigatorio" v-if="grupo_complementos.minimo > 0">
                                        <span>Obrigatório</span>
                                    </div>
                                </div>
                            </div>
                            <ul v-if="grupo_complementos.por_quantidade" class="por_quantidade">
                                <li v-for="complementos in grupo_complementos.complementos" :key="complementos.id">
                                    <div class="texto_extra">
                                        <div class="titulo_extra">
                                            {{complementos.nome}}
                                        </div>
                                        <div v-if="complementos.valores[2].valor_desconto > 0" class="valor_extra">
                                            + {{complementos.valores[2].valor_desconto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                        </div>
                                    </div>
                                    <div class="botoes_adicional">
                                        <button v-if="complementos.quantidade > 0" @click="complementos.quantidade > 0 ? complementos.quantidade-- : null">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                        <div class="qtde_extra" v-if="complementos.quantidade > 0">
                                            {{complementos.quantidade || 0}}
                                        </div>
                                        <button @click="adicionaComplemento(grupo_complementos, complementos)">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </div>
                                </li>
                            </ul>
                            <ul v-if="!grupo_complementos.por_quantidade" class="por_quantidade">
                                <li v-for="complementos in grupo_complementos.complementos" :key="complementos.id">
                                    <div class="texto_extra">
                                        <div class="titulo_extra">
                                            {{complementos.nome}}
                                        </div>
                                    </div>
                                    <div class="botoes_adicional">
                                        <v-switch
                                            hide-details
                                            :disabled="grupo_complementos.bloqueio && !complementos.retirar ? true : false"
                                            v-model="complementos.retirar"
                                            @click="adicionaRemoveComplemento(grupo_complementos)"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div v-if="item_.tipo_produto == 'pizza'">
                            <div class="div-wrapper-opcoes-extras">
                                <div class="titulo">
                                    <div class="nome">
                                        Massas
                                    </div>
                                    <div class="minmax">
                                        'Mínimo: 1 /  Máximo: 1'
                                        <div class="obrigatorio">
                                            <span>Obrigatório</span>
                                        </div>
                                    </div>
                                </div>
                                <ul class="por_quantidade">
                                    <li v-for="complementos in item_.massas" :key="complementos.id">
                                        <div class="texto_extra">
                                            <div class="titulo_extra">
                                                {{complementos.nome}}
                                            </div>
                                            <div v-if="complementos.valores[2].valor_desconto > 0" class="valor_extra">
                                                + {{complementos.valores[2].valor_desconto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                            </div>
                                            <div v-else-if="complementos.valores[2].valor > 0" class="valor_extra">
                                                + {{complementos.valores[2].valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                            </div>
                                        </div>
                                        <div class="botoes_adicional">
                                            <v-switch
                                                hide-details
                                                :disabled="item_.bloqueio_massas && !complementos.selecionado ? true : false"
                                                v-model="complementos.selecionado"
                                                @click="adicionaRemoveComplementoPizza(item_, 'massas')"
                                            />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="div-wrapper-opcoes-extras">
                                <div class="titulo">
                                    <div class="nome">
                                        Bordas
                                    </div>
                                    <div class="minmax">
                                        'Mínimo: 1 /  Máximo: 1'
                                        <div class="obrigatorio">
                                            <span>Obrigatório</span>
                                        </div>
                                    </div>
                                </div>
                                <ul class="por_quantidade">
                                    <li v-for="complementos in item_.bordas" :key="complementos.id">
                                        <div class="texto_extra">
                                            <div class="titulo_extra">
                                                {{complementos.nome}}
                                            </div>
                                            <div v-if="complementos.valores[2].valor_desconto > 0" class="valor_extra">
                                                + {{complementos.valores[2].valor_desconto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                            </div>
                                            <div v-else-if="complementos.valores[2].valor > 0" class="valor_extra">
                                                + {{complementos.valores[2].valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                            </div>
                                        </div>
                                        <div class="botoes_adicional">
                                            <v-switch
                                                hide-details
                                                :disabled="item_.bloqueio_bordas && !complementos.selecionado ? true : false"
                                                v-model="complementos.selecionado"
                                                @click="adicionaRemoveComplementoPizza(item_, 'bordas')"
                                            />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="div-wrapper-opcoes-extras">
                                <div class="titulo">
                                    <div class="nome">
                                        Sabores
                                    </div>
                                    <div class="minmax">
                                        'Mínimo: 1 /  Máximo: {{item_.fracoes_aceitas.fracoes_aceitas.length}}'
                                        <div class="obrigatorio">
                                            <span>Obrigatório</span>
                                        </div>
                                    </div>
                                </div>
                                <ul class="por_quantidade">
                                    <li v-for="complementos in item_.sabores" :key="complementos.id">
                                        <div class="texto_extra">
                                            <div class="titulo_extra">
                                                {{complementos.nome}}
                                            </div>
                                            <div v-if="complementos.valores[2].valor_desconto > 0" class="valor_extra">
                                                + {{complementos.valores[2].valor_desconto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                            </div>
                                            <div v-else-if="complementos.valores[2].valor > 0" class="valor_extra">
                                                + {{complementos.valores[2].valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                            </div>
                                        </div>
                                        <div class="botoes_adicional">
                                            <v-switch
                                                hide-details
                                                :disabled="item_.bloqueio_sabores && !complementos.selecionado ? true : false"
                                                v-model="complementos.selecionado"
                                                @click="adicionaRemoveComplementoPizza(item_, 'sabores')"
                                            />
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="div-wrapper-opcoes-extras">
                            <div class="titulo">
                                Alguma observação?
                            </div>
                            <div class="opcoes-extras-observacoes">
                                <v-textarea
                                    solo
                                    v-model="item_.observacoes"
                                    label="Por exemplo: tirar a salada, maionese, molho à parte..."
                                />
                            </div>
                        </div>
                    </div>
                    <div class="botoes">
                        <div class="btn-wrapper">
                            <div class="botoes_adicionar">
                                <button @click="item_.quantidade > 1 ? item_.quantidade-- : null" v-bind:style="item_.quantidade <= 1 ? 'color: rgba(0, 0, 0, 0.35)' : '' ">
                                    <i class="fas fa-minus"></i>
                                </button>
                                <div class="qtde_extra">
                                    {{item_.quantidade || 0}}
                                </div>
                                <button @click="item_.quantidade++">
                                    <i class="fas fa-plus"></i>
                                </button>
                            </div>
                            <v-btn class="adiciona-produto" @click="adicionaProduto(item_.grupo_complementos)">
                                <img :src="iconeCesta" alt="">
                                <span v-if="$store.getters.screen_size.width > 320">
                                    Adicionar
                                </span>
                                <span class="preco">
                                    {{total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                </span>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <Loader v-if="$store.getters.status_carrinho == 'loading'"/> -->
        </v-card>
    </v-dialog>
</template>

<script>
    import store from '@/store'
    import CestaCompras from '@/assets/images/CESTADECOMPRAS.png'
    // import Loader from '@/components/Loader2.vue'

    export default {
		name: 'ModalAddProduto',
        components: {
            // Loader,
        },
        props: [
            "show",
            "id",
            "categoria",
            "tipo_produto",
        ],
        data: () => ({
            show_modal: false,
            item_: null,
            iconeCesta: CestaCompras,
        }),
		methods: {
            async close(adicionado){
                this.show_modal = await false
                setTimeout(() => {
                    this.item_ = null
                    this.$emit('handleClose', adicionado)
                }, 300);
            },
            async init(){
                this.item_ = await store.getters.item_cardapio({categoria: this.categoria, id: this.id, tipo_produto: this.tipo_produto})
                this.show_modal = this.show
            },
            async adicionaProduto(grupo_complementos){
                let tmp = {
                    quantidade: this.item_.quantidade,
                    preco: this.item_.valor_desconto > 0 ? this.item_.valor_desconto : (this.item_.valor || 0),
                    id: this.item_.id,
                    nome: this.item_.nome,
                    descricao: this.item_.descricao,
                    acompanhamentos: this.item_.acompanhamentos,
                    referencia: this.item_.referencia,
                    imagem: this.item_.imagem,
                    visivel_complementos: this.item_.visivel_complementos,
                    observacoes: this.item_.observacoes,
                    grupo_complementos: [],
                    tipo_produto: this.item_.tipo_produto,
                    codigo_tamanho: this.item_.tipo_produto == 'pizza' ? this.item_.codigo_tamanho : null,
                    borda: [],
                    massa: [],
                    sabores: [],
                };
                
                if(grupo_complementos){
                    for(let i = 0; i < grupo_complementos.length; i++){
                        if(grupo_complementos[i].por_quantidade && grupo_complementos[i].minimo){
                            let quantidade_complementos = 0
                            for(let j = 0; j < grupo_complementos[i].complementos.length; j++){
                                quantidade_complementos += grupo_complementos[i].complementos[j].quantidade
                            }
                            if(quantidade_complementos < grupo_complementos[i].minimo){
                                this.$swal({
                                    title: 'Atenção',
                                    icon:'warning',
                                    text: grupo_complementos[i].nome + ' exige uma quantidade mínima!',
                                    showCancelButton: false,
                                    showCloseButton: false,
                                    showConfirmButton: true,
                                    confirmButtonText:'Ok'
                                })
                                return false
                            }
                        }else if(grupo_complementos[i].minimo){
                            let quantidade_complementos = 0
                            for(let j = 0; j < grupo_complementos[i].complementos.length; j++){
                                if(grupo_complementos[i].complementos[j].retirar){
                                    quantidade_complementos ++
                                }
                            }
                            if(quantidade_complementos < grupo_complementos[i].minimo){
                                this.$swal({
                                    title: 'Atenção',
                                    icon:'warning',
                                    text: grupo_complementos[i].nome + ' exige uma quantidade mínima!',
                                    showCancelButton: false,
                                    showCloseButton: false,
                                    showConfirmButton: true,
                                    confirmButtonText:'Ok'
                                })
                                return false
                            }
                        }
                    }
                }
                
                if(this.item_.bordas){
                    let bordas = this.item_.bordas
                    let selecionado = true;
                    for(let i = 0; i < bordas.length; i++){
                        const element = bordas[i];
                        if(element.selecionado){
                            selecionado = false;
                            
                            const valor = element.valores && element.valores[2].valor_desconto ? element.valores[2].valor_desconto : element.valores[2].valor;

                            tmp.borda.push({
                                id: element.codigo_borda,
                                nome: element.nome,
                                valor: valor,
                                quantidade: 1
                            });

                            tmp.preco += valor;
                        }
                    }

                    if(selecionado){
                        this.$swal({
                            title: 'Atenção',
                            icon:'warning',
                            text: 'Bordas exige uma quantidade mínima!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true,
                            confirmButtonText:'Ok'
                        })
                        return false
                    }
                }
                
                if(this.item_.massas){
                    let massas = this.item_.massas
                    let selecionado = true;
                    for(let i = 0; i < massas.length; i++){
                        const element = massas[i];
                        if(element.selecionado){
                            selecionado = false;

                            const valor = element.valores && element.valores[2].valor_desconto ? element.valores[2].valor_desconto : element.valores[2].valor;

                            tmp.massa.push({
                                id: element.codigo_massa,
                                nome: element.nome,
                                valor: valor,
                                quantidade: 1
                            });

                            tmp.preco += valor;
                        }
                    }

                    if(selecionado){
                        this.$swal({
                            title: 'Atenção',
                            icon:'warning',
                            text: 'Massas exige uma quantidade mínima!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true,
                            confirmButtonText:'Ok'
                        })
                        return false
                    }
                }
                
                if(this.item_.sabores){
                    let sabores = this.item_.sabores
                    let selecionado = true;
                    let quantidade = 0;
                    let empresa_forma_calculo_id = store.getters.empresa_forma_calculo_id;
                    let total_sabores_ = 0;
                    let maior_sabor_ = 0;
                    let valor_base_sabor = 0;
                    for(let i = 0; i < sabores.length; i++){
                        const element = sabores[i];
                        if(element.selecionado){
                            selecionado = false;

                            const valor = element.valores && element.valores[2].valor_desconto ? element.valores[2].valor_desconto : element.valores[2].valor;
                            total_sabores_ += valor;
                            quantidade++;

                            if(valor > valor_base_sabor){
                                maior_sabor_ = valor;
                            }

                            tmp.sabores.push({
                                id: element.codigo_sabor,
                                nome: element.nome,
                                valor: valor,
                                quantidade: 1
                            });
                        }
                    }

                    switch(empresa_forma_calculo_id){
                        case 1:
                            tmp.preco += (this.item_.valores[2].valor_desconto != null ? this.item_.valores[2].valor_desconto : this.item_.valores[2].valor)
                        break;
                        case 2:
                            tmp.preco += (total_sabores_/quantidade)
                        break;
                        case 3:
                            tmp.preco += maior_sabor_;
                        break;
                    }

                    if(selecionado){
                        this.$swal({
                            title: 'Atenção',
                            icon:'warning',
                            text: 'Sabores exige uma quantidade mínima!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true,
                            confirmButtonText:'Ok'
                        })
                        return false
                    }
                }

                if(this.item_.grupo_complementos != null){
                    await this.item_.grupo_complementos.forEach(async grupo_complemento => {
                        let tmp_grupo_complemento = {
                            nome:grupo_complemento.nome,
                            complementos:[],
                            por_quantidade:grupo_complemento.por_quantidade
                        }
                        
                        if(grupo_complemento.complementos != null){
                            grupo_complemento.complementos.forEach( async complemento => {
                                if((tmp_grupo_complemento.por_quantidade && complemento.quantidade > 0) || (!tmp_grupo_complemento.por_quantidade && complemento.retirar)){
                                    const valor = complemento.valores ? complemento.valores[2].valor_desconto : 0;
                                    if(complemento.retirar){
                                        complemento.quantidade = 1;
                                    }
                                    await tmp_grupo_complemento.complementos.push({
                                        id: complemento.produto_complemento_id,
                                        nome: complemento.nome,
                                        valor: valor,
                                        quantidade: complemento.quantidade
                                    })

                                    // tmp.preco += valor * complemento.quantidade;
                                }
                            });
                        }

                        if(tmp_grupo_complemento.complementos.length > 0){
                            await tmp.grupo_complementos.push(tmp_grupo_complemento);
                        }
                    });
                }

                let resp = store.dispatch('addItemCarrinho', {item: tmp});
                if(resp){
                    this.$swal({
                        icon:'success',
					    text: 'Item adicionado ao carrinho',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    }).then(() => {
                        this.close(true)
                    })
                }
            },
            adicionaComplemento(grupo_complemento, complemento){

                let quantidade_complementos = 0
                for(let i = 0; i < grupo_complemento.complementos.length; i++){
                    quantidade_complementos += grupo_complemento.complementos[i].quantidade
                }

                if(quantidade_complementos < grupo_complemento.maximo){
                    complemento.quantidade++
                }
                else{
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
                        text: 'A quantidade atingiu o limite!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                }
            },
            adicionaRemoveComplemento(grupo_complemento){
                let quantidade_complementos = 0

                for(let i = 0; i < grupo_complemento.complementos.length; i++){
                    if(grupo_complemento.complementos[i].retirar){
                        quantidade_complementos ++;
                    }
                }

                if(quantidade_complementos >= grupo_complemento.maximo){
                    grupo_complemento.bloqueio = true;
                }else{
                    grupo_complemento.bloqueio = false;
                }
            },
            adicionaRemoveComplementoPizza(item, tipo){
                let quantidade_complementos = 0

                for(let i = 0; i < item[tipo].length; i++){
                    if(item[tipo][i].selecionado){
                        quantidade_complementos ++;
                    }
                }

                let referencia = '';

                switch(tipo){
                    case 'bordas':
                    case 'massas':
                        referencia = 1;
                    break;
                    case 'sabores':
                        referencia = item.fracoes_aceitas.fracoes_aceitas.length;
                    break;
                }

                if(quantidade_complementos >= referencia){
                    item['bloqueio_'+tipo] = true;
                }else{
                    item['bloqueio_'+tipo] = false;
                }
            },
		},
        computed: {
            total: function () {
                let total_ = 0;

                if(this.item_.grupo_complementos != null){
                    this.item_.grupo_complementos.forEach(grupo_complemento => {
                        if(grupo_complemento.complementos != null){
                            grupo_complemento.complementos.forEach(complemento => {
                                if(complemento.valores != null){
                                    if(complemento.valores[2].valor_desconto != null){
                                        total_ += complemento.valores[2].valor_desconto * complemento.quantidade
                                    }else{
                                        total_ += complemento.valores[2].valor * complemento.quantidade
                                    }
                                }
                            });
                        }
                    });
                }
                if(this.item_.bordas){
                    this.item_.bordas.forEach(complemento => {
                        if(complemento.selecionado){
                        //     grupo_complemento.complementos.forEach(complemento => {
                                if(complemento.valores != null){
                                    if(complemento.valores[2].valor_desconto != null){
                                        total_ += complemento.valores[2].valor_desconto
                                    }else{
                                        total_ += complemento.valores[2].valor
                                    }
                                }
                            // });
                        }
                    });
                }
                if(this.item_.massas){
                    this.item_.massas.forEach(complemento => {
                        if(complemento.selecionado){
                        //     grupo_complemento.complementos.forEach(complemento => {
                                if(complemento.valores != null){
                                    if(complemento.valores[2].valor_desconto != null){
                                        total_ += complemento.valores[2].valor_desconto
                                    }else{
                                        total_ += complemento.valores[2].valor
                                    }
                                }
                            // });
                        }
                    });
                }
                if(this.item_.sabores){
                    let quantidade = 0;
                    let empresa_forma_calculo_id = store.getters.empresa_forma_calculo_id;
                    let total_sabores_ = 0;
                    let maior_sabor_ = 0;
                    let valor_base_sabor = 0;
                    this.item_.sabores.forEach(complemento => {
                        if(complemento.selecionado){
                            quantidade++;
                        //     grupo_complemento.complementos.forEach(complemento => {
                                if(complemento.valores != null){
                                    if(complemento.valores[2].valor_desconto != null){
                                        total_sabores_ += complemento.valores[2].valor_desconto
                                        valor_base_sabor = complemento.valores[2].valor_desconto
                                    }else{
                                        total_sabores_ += complemento.valores[2].valor
                                        valor_base_sabor = complemento.valores[2].valor
                                    }
                                    if(valor_base_sabor > maior_sabor_){
                                        maior_sabor_ = valor_base_sabor;
                                    }
                                }
                            // });
                        }
                    });
                    if(quantidade > 0 && total_sabores_ > 0){
                        switch(empresa_forma_calculo_id){
                            case 1:
                                total_ += (this.item_.valores[2].valor_desconto != null ? this.item_.valores[2].valor_desconto : this.item_.valores[2].valor)
                            break;
                            case 2:
                                total_ += (total_sabores_/quantidade)
                            break;
                            case 3:
                                total_ += maior_sabor_;
                            break;
                        }
                    }
                }

                if(this.item_.valor_desconto || this.item_.valor){
                    if(this.item_.valor_desconto > 0){
                        total_ += parseFloat(this.item_.valor_desconto)
                    }else{
                        total_ += parseFloat(this.item_.valor)            
                    }
                }

                return total_ *= this.item_.quantidade
            }
        },
        mounted(){
            this.init()
		},
	}
</script>

<style lang="scss">
    .v-dialog{
        box-shadow: none
    }
    .modal-mensagem{
        border-radius: 40px !important;
        @media (max-width: 767px) {
            border-radius: 0px !important;
        }
    }
    .modal-add-produto{
        list-style: none;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        padding: 15px;
        .close-modal{
            background-color: transparent !important;
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 20px;
            svg{
                font-size: 25px;
                margin-left: 10px;
                background-color: transparent;
                width: 40px;
                height: 40px;
                padding: 8px;
                border-radius: 30px;
                transition: all ease .3s;
                path{
                    fill: $primarycolor;
                }
                &:hover{
                    background-color: #f3f3f3;
                }
                &:active{
                    background-color: #dadada;
                }
            }
        }
        .divider{
            width: 100%;
        }
        .imagem{
            display: flex;
            flex-wrap: wrap;
            border-radius: 20px;
            .img{
                flex: 1;
                border-radius: 20px;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                @media (max-width: 767px) {
                    display: none;
                }
            }
        }
        .texto{
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            padding: 15px 0 0 15px;
            @media (max-width: 767px) {
                padding: 0;
            }
            .nome{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                display: flex;
                align-items: center;
                color: #242424;
                .referencia{
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    display: flex;
                    align-items: center;
                    color: #A7A8AA;
                    margin-left: 10px;
                }
            }
            .acompanhamento,
            .descricao{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #787885;
                p{
                    margin-bottom: 0;
                }
            }
        }
        .opcoes_extra{
            padding: 15px 0 15px 15px;
            height: 325px;
            overflow-y: auto;
            @media (max-width: 767px) {
                padding: 12px 0;
                height: auto;
            }
            .div-wrapper-opcoes-extras{
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                .opcoes-extras-observacoes{
                    width: 100%;
                    .v-input__slot{
                        box-shadow: none;
                        padding: 0 30px;
                        label{
                            color: #C8C8C8;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                        }
                        textarea{
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: #242424;
                            @media (max-width: 767px) {
                                height: 48px;
                            }
                        }
                        @media (max-width: 767px) {
                            padding: 0 12px;
                        }
                    }
                }
                .titulo{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #FFFFFF;
                    width: 100%;
                    padding: 10px 10px 10px 30px;
                    background: #404040;
                    box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12), 0px 10px 32px -4px rgba(24, 39, 75, 0.1);
                    .minmax{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        .obrigatorio{
                            background-color: $primarycolor;
                            border-radius: 5px;
                            padding: 2px 6px;
                            margin-left: 10px;
                            font-size: 14px;
                            text-transform: uppercase;
                        }
                    }
                    @media (max-width: 767px) {
                        padding: 10px;
                    }
                }
                ul{
                    padding: 10px 0 0;
                    li{
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 30px;
                        color: #242424;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        flex: 1;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 10px;
                        .texto_extra{
                            display: flex;
                            flex-wrap: wrap;
                            flex: 1;
                            align-items: center;
                            label,
                            .titulo_extra{
                                width: 100%;
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                                display: flex;
                                align-items: center;
                                color: #242424;
                            }
                            .valor_extra{
                                width: 100%;
                                font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 18px;
                                display: flex;
                                align-items: center;
                                color: #FE6B15;
                            }
                        }
                        .v-input{
                            margin-top: 0!important;
                        }
                        .botoes_adicional{
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            color: #787885;
                            font-family: Poppins;
                            font-weight: normal;
                            font-size: 25px;
                            line-height: 35px;
                            border-radius: 5px;
                            .qtde_extra{
                                width: 45px;
                                text-align: center;
                            }
                            button{
                                border-radius: 3px;
                                padding: 10px;
                                line-height: 1;
                                font-size: 15px;
                                color: #FE6B15;
                            }
                        }
                        
                    }
                    &.nao_por_quantidade{
                        flex: 1;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        li{
                            display: flex;
                            padding-left: 30px;
                            width: calc(100% / 3);
                            flex: initial;
                            @media (max-width: 767px) {
                                padding: 0 12px;
                                width: 100%;
                            }
                        }
                    }
                    &.por_quantidade{
                        flex: 1;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        li{
                            flex: initial;
                            width: 100%;
                            padding: 15px;
                            margin-bottom: 0;
                            &:nth-of-type(odd){
                                background: rgba(237, 237, 237, 0.4);
                            }
                        }
                    }
                }
            }
        }
        .total{
            padding: 0 15px;
            display: flex;
            justify-content: flex-end;
            .preco{
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #262626;
                border: 1px solid #EDEDED;
                box-sizing: border-box;
                border-radius: 6px;
                padding: 5px 10px;
            }
            @media (max-width: 767px) {
                padding: 0;
            }
        }
        .btn-wrapper{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            border-top: 2px solid #E5E5E5;
            .botoes_adicionar{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                border: 2px solid #FE8204;
                color: #000000;
                font-family: 'Poppins';
                font-weight: normal;
                font-size: 25px;
                line-height: 35px;
                border-radius: 5px;
                margin: 15px 0 0px 15px;
                .qtde_extra{
                    width: 45px;
                    text-align: center;
                }
                button{
                    border-radius: 3px;
                    padding: 10px;
                    line-height: 1;
                    font-size: 12px;
                    color: #FE6B15;
                }
                @media (max-width: 767px) {
                    margin: 5px 0 0px 5px;
                    flex: 1;
                    justify-content: center;
                    button{
                        padding: 5px;
                        line-height: 1;
                        font-size: 12px;
                        color: #FE6B15;
                    }
                }
            }
            .adiciona-produto{
                color: #fff;
                background-color: #FE6B15 !important;
                font-family: 'Poppins';
                font-weight: 700;
                padding: 15px !important;
                border-radius: 6px;
                height: auto !important;
                min-width: auto !important;
                margin: 15px 0 0px 15px;
                font-size: 21.33px;
                line-height: 32px;
                letter-spacing: 0;
                img{
                    width: 30px;
                    margin-right: 10px;
                }
                .preco{
                    display: flex;
                    margin-left: 15px;
                    min-width: 105px;
                }
                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 1;
                    margin: 5px 0 0px 5px;
                    .preco{
                        margin-left: 10px;
                        min-width: 0px;
                    }
                    img{
                        width: 22px;
                    }
                }
            }
        }
        @media (max-width: 767px) {
            padding: 0;
        }
    } 
</style>